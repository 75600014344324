// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articulo-mysql-all-articles-page-url-js": () => import("./../../../src/pages/articulo/{mysqlAllArticles.pageUrl}.js" /* webpackChunkName: "component---src-pages-articulo-mysql-all-articles-page-url-js" */),
  "component---src-pages-busqueda-receta-js": () => import("./../../../src/pages/busqueda-receta.js" /* webpackChunkName: "component---src-pages-busqueda-receta-js" */),
  "component---src-pages-crear-cuenta-js": () => import("./../../../src/pages/crear-cuenta.js" /* webpackChunkName: "component---src-pages-crear-cuenta-js" */),
  "component---src-pages-curso-mysql-all-courses-page-url-js": () => import("./../../../src/pages/curso/{mysqlAllCourses.pageUrl}.js" /* webpackChunkName: "component---src-pages-curso-mysql-all-courses-page-url-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-curso-tortas-de-cumpleanos-js": () => import("./../../../src/pages/landing/curso-tortas-de-cumpleanos.js" /* webpackChunkName: "component---src-pages-landing-curso-tortas-de-cumpleanos-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-paginas-contacto-js": () => import("./../../../src/pages/paginas/contacto.js" /* webpackChunkName: "component---src-pages-paginas-contacto-js" */),
  "component---src-pages-paginas-disclaimers-js": () => import("./../../../src/pages/paginas/disclaimers.js" /* webpackChunkName: "component---src-pages-paginas-disclaimers-js" */),
  "component---src-pages-paginas-politica-de-cookies-js": () => import("./../../../src/pages/paginas/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-paginas-politica-de-cookies-js" */),
  "component---src-pages-paginas-politica-de-privacidad-js": () => import("./../../../src/pages/paginas/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-paginas-politica-de-privacidad-js" */),
  "component---src-pages-receta-mysql-all-recipes-page-url-js": () => import("./../../../src/pages/receta/{mysqlAllRecipes.pageUrl}.js" /* webpackChunkName: "component---src-pages-receta-mysql-all-recipes-page-url-js" */),
  "component---src-pages-recetas-de-postres-js": () => import("./../../../src/pages/recetas-de-postres.js" /* webpackChunkName: "component---src-pages-recetas-de-postres-js" */),
  "component---src-pages-recetas-mysql-all-categories-page-url-js": () => import("./../../../src/pages/recetas/{mysqlAllCategories.pageUrl}.js" /* webpackChunkName: "component---src-pages-recetas-mysql-all-categories-page-url-js" */),
  "component---src-pages-todas-las-recetas-js": () => import("./../../../src/pages/todas-las-recetas.js" /* webpackChunkName: "component---src-pages-todas-las-recetas-js" */),
  "component---src-pages-todos-los-articulos-js": () => import("./../../../src/pages/todos-los-articulos.js" /* webpackChunkName: "component---src-pages-todos-los-articulos-js" */)
}

